import { useState } from 'react'
import HeatPumpsPopup from './popups/HeatPumpsPopup'
import WaterPopup from './popups/WaterPopup'

const OurProducts = (ref) => {
  const [show, setShow] = useState(false)
  const [showWater, setShowWater] = useState(false)

  const toggleFunction = (show) => {
    setShow(show)
  }

  const toggleFunctionWater = (showWater) => {
    setShowWater(showWater)
  }

  return (
    <>
      <div id="ourProduts" className="product-info">
        <div
          style={{
            textAlign: 'center',
          }}
          className="header-product"
        >
          <div
            style={{
              color: '#656C78',
              letterSpacing: 3,
              marginBottom: 20,
              fontWeight: 700,
              fontSize: 14,
            }}
          >
            OUR PRODUCTS
          </div>
          <div
            style={{
              color: '#252521',
              fontSize: '40px',
              fontWeight: 600,
            }}
          >
            New tools for old problems.
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            marginBottom: '0px',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
          }}
        >
          <div
            className="product-image"
            style={{
              flex: 1,
              marginBottom: 20,
            }}
          >
            <img
              src="/image-2.png"
              alt="img"
              className="img-2"
              style={{
                clipPath: 'view-box',
                borderRadius: 25,
                maxWidth: 440,
              }}
            />
          </div>
          <div
            style={{
              marginLeft: 12,
              margin: 'auto',
              flex: 1,
              maxWidth: 483,
            }}
          >
            <div
              style={{
                marginBottom: 30,
                fontSize: 24,
                fontWeight: 700,
                color: '#252521',
              }}
            >
              HeatPumps
            </div>

            {show && <HeatPumpsPopup toggleFunction={toggleFunction} />}
            <div style={{ marginBottom: 30, lineHeight: 1.5 }}>
              Matching pace with a velocity of change, row shift to renewable
              energy with our versatile product line and services. Matching pace
              with a velocity of change, row shift to renewable energy with our
              versatile product line and services.{' '}
              <span
                style={{
                  color: '#FF8966',
                  fontSize: 15,
                  fontWeight: 600,
                  cursor: 'pointer',
                }}
                onClick={() => setShow(true)}
              >
                Read More
              </span>
            </div>

            <div>
              <div
                style={{
                  fontWeight: 700,
                  color: '#252521',
                }}
              >
                Advantages
              </div>
              <div
                style={{
                  display: 'flex',
                  color: '#252521',
                  fontSize: 14,
                  marginTop: 15,
                  justifyContent: 'space-between',
                }}
              >
                <div
                // classname="heatpump-left-text"
                >
                  <div className="hr-border" style={{ paddingBottom: 10 }}>
                    Highly effective
                  </div>
                  <hr className="display-none" />
                  <div style={{ marginBottom: 15, paddingTop: 10 }}>
                    Ease of installation and <br />
                    maintenance
                  </div>
                  <div
                    style={{
                      marginBottom: 15,
                      color: '#FF8966',
                      fontWeight: 600,
                      fontSize: 14,
                      minWidth: 187,
                      cursor: 'pointer',
                    }}
                    onClick={() => setShow(true)}
                  >
                    View All Advantages
                    <img
                      src="/arrow.png"
                      alt=""
                      style={{
                        width: 19,
                        marginLeft: 14,
                      }}
                    />
                  </div>
                </div>

                <div
                  style={{
                    marginLeft: 7,
                    color: '#252521',
                    fontSize: 14,
                    minWidth: 120,
                    // border: '2px solid black',
                  }}
                >
                  <div className="hr-border" style={{ paddingBottom: 10 }}>
                    Eco Friendly
                  </div>
                  <hr className="display-none" />
                  <div style={{ marginBottom: 35, paddingTop: 10 }}>
                    All weather comfort
                  </div>
                  <div
                    onClick={() => setShow(true)}
                    style={{
                      color: '#FF8966',
                      fontWeight: 600,
                      fontSize: 14,
                      cursor: 'pointer',
                    }}
                    className="display-none"
                  >
                    Why Heatpumps over Geyser
                    <img
                      // className="heatpump-arrow"
                      src="/arrow.png"
                      alt=""
                      style={{
                        width: 19,
                        marginLeft: 14,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Water Softners */}
      <div
        style={{
          marginTop: '100px',
          marginLeft: 'auto',
          marginRight: 'auto',
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
        className="center-container flex-reverse"
      >
        <div
          style={{
            flex: 1,
            maxWidth: 500,
            // minWidth: 350,
          }}
        >
          <div
            style={{
              marginBottom: 30,
              fontSize: 24,
              fontWeight: 700,
              color: '#252521',
            }}
          >
            Water Softners
          </div>
          {showWater && (
            <WaterPopup toggleFunctionWater={toggleFunctionWater} />
          )}
          <div style={{ marginBottom: 30, lineHeight: 1.5 }}>
            A Water Softener is a filtration system that works to remove high
            concentrations of calcium and magnesium that cause hard water. When
            water flows through a Water Softener, the system filters out these
            hard water minerals .
            <span
              onClick={() => setShowWater(true)}
              style={{
                color: '#FF8966',
                fontWeight: 600,
                fontSize: 15,
                cursor: 'pointer',
              }}
            >
              {' '}
              Read More
            </span>
          </div>

          <div>
            <div style={{ fontWeight: 700, color: '#252521' }}>Advantages</div>
            <div
              style={{
                display: 'flex',
                color: '#252521',
                fontSize: 14,
                marginTop: 15,
                justifyContent: 'space-between',
              }}
            >
              <div>
                <div
                  className="hr-border water-upper-text"
                  style={{ paddingBottom: 10 }}
                >
                  Saves Money
                </div>
                <hr className="display-none" />
                <div
                  className="water-brighter-softer"
                  style={{ marginBottom: 15, paddingTop: 10 }}
                >
                  Brighter and softer clothes
                </div>
                <div
                  style={{
                    marginBottom: 15,
                    marginTop: 35,
                    color: '#FF8966',
                    fontWeight: 600,
                    fontSize: 14,
                    minWidth: 187,
                    cursor: 'pointer',
                    // border: '1px solid blue',
                  }}
                  onClick={() => setShowWater(true)}
                >
                  View All Advantages
                  <img
                    src="/arrow.png"
                    alt=""
                    style={{
                      width: 19,
                      marginLeft: 14,
                    }}
                  />
                </div>
              </div>

              <div
                style={{
                  marginLeft: 7,
                  color: '#252521',
                  fontSize: 14,
                }}
              >
                <div className="water-upper-text" style={{ paddingBottom: 10 }}>
                  Cleaner hair and softer skin
                </div>
                <hr />
                <div style={{ marginBottom: 35, paddingTop: 10 }}>
                  Less time cleaning
                </div>
              </div>
            </div>
          </div>
        </div>

        <img
          src="/image-3.png"
          alt="img"
          className="img-2"
          width={500}
          style={{ borderRadius: 23, marginBottom: 20 }}
        />
      </div>

      {/* ----------------------Booster Pumps------------------------- */}

      <div
        className="center-container booster-container"
        style={{
          margin: '100px auto 0px auto',
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
          marginBottom: '50px',
        }}
      >
        <img
          className="img-2"
          src="/image-32.png"
          alt="img"
          width={500}
          style={{
            borderRadius: 22,
            marginBottom: 20,
          }}
        />

        <div
          className="boosterpumps-container"
          style={{
            marginLeft: 12,
            maxWidth: 500,
            minWidth: 300,
            flex: 1,
          }}
        >
          <div
            style={{
              marginBottom: 30,
              fontSize: 24,
              fontWeight: 700,
              color: '#252521',
              textAlign: 'left',
            }}
          >
            Booster Pumps
          </div>

          <div style={{ marginBottom: 30, lineHeight: 1.5, textAlign: 'left' }}>
            A booster pump works just like a fan with blades that spin to
            increase air movement with an impeller that increases flow and
            pressure. In a similar manner to a fan with blades that spin around
            to increase air movement the booster pump uses an impeller to
            increase the water flow rate and pressure.
          </div>
        </div>
      </div>
    </>
  )
}

export default OurProducts
