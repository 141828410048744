import React, { useEffect, useRef } from 'react'

const WaterPopup = ({ toggleFunctionWater }) => {
  let menuRef = useRef()

  useEffect(() => {
    let handler = (e) => {
      console.log('e.target contains', e.target)

      if (menuRef.current && !menuRef.current.contains(e.target)) {
        console.log('Clicked outside the component')
        toggleFunctionWater(false)
      }
    }

    document.addEventListener('mousedown', handler)
  })

  return (
    <div
      style={{
        maxWidth: '100vw',
        height: '100vh',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        position: 'fixed',
        background: 'rgba(49, 49, 49, 0.8)',
        zIndex: 1,
      }}
    >
      <div
        ref={menuRef}
        style={{
          position: 'absolute',
          background: 'white',
          borderRadius: 10,
          margin: 'auto',
          maxWidth: '80%',
          left: '0',
          right: '0',
          top: 50,
          bottom: 50,
          overflowY: 'scroll',
        }}
      >
        <div
          style={{
            maxWidth: '92%',
            margin: '5px auto 60px auto',
            color: '#252521',
            fontWeight: 400,
            fontSize: 21,
            lineHeight: 1.8,
            // overflowY: 'scroll',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              margin: '35px 0px 15px 0px',
              color: '#252521',
              fontWeight: 700,
              fontSize: 22,
            }}
          >
            <div style={{ fontSize: 30 }}>Water Softners</div>
            <div
              onClick={() => {
                toggleFunctionWater(false)
              }}
            >
              X
            </div>
          </div>
          <div>
            A Water Softener is a filtration system that works to remove high
            concentrations of calcium and magnesium that cause hard water. When
            water flows through a Water Softener, the system filters out these
            hard water minerals and the softened water then leaves the water
            softening system to flow through plumbing.
          </div>
          <br />
          <div style={{ fontWeight: 700, fontSize: 24 }}>
            Problems faced because of hard water ?
          </div>
          <br />
          Living in a home with hard water can be a costly problem. When
          groundwater flows through the pipes of your home the dissolved
          minerals combine with heat, leaving scale and buildup that damages
          your pipes, appliances, and fixtures.
          <br /> <br />
          When water falls as rain, it's pure and void of minerals or soft
          water. As it flows through the ground, it collects minerals like
          calcium and magnesium along the way. Water that has collected a large
          amount of minerals, is classified as hard water. Hard water is not
          harmful to your health, but it is harmful to your home.
          <br />
          <br /> A water softener works to treat hard water by removing the
          minerals responsible through a process called ion-exchange. Mineral
          ions that cause hardness and damage to your home are trapped by resin
          within the system and exchanged for sodium and potassium ions.
          <br />
          <br />
          <div style={{ fontSize: 24, fontWeight: 700, lineHeight: 1.8 }}>
            Advantages of a home water softener
            <br />
            <br />
          </div>
          <span style={{ fontWeight: 600, fontSize: 22 }}>
            Saves money
          </span>{' '}
          <br />
          <br />
          Soft water lacks the mineral ions that cause buildup in your pipes and
          appliances, saving you from costly repair bills. Mineral buildup in a
          pipe narrows the area water can move through, which requires a higher
          pump pressure. It will also increase the amount of energy needed to
          keep water hot or cold. The buildup also wreaks havoc on your
          appliances, which means more frequent repair or replacement costs for
          your dishwasher, laundry machines, coffee machines, water heaters, and
          ice makers.
          <br />
          <br />
          <span style={{ fontWeight: 600, fontSize: 22 }}>
            How a water softener saves you money
          </span>
          <br />
          <br />
          <span style={{ fontWeight: 600, fontSize: 22 }}>Plumbing</span>: As
          minerals in hard water travel through your pipes they stick, forming a
          scale buildup that will eventually clog the pipes. In homes with
          severe hard water compositions, buildup can cause irreversible damage
          to plumbing, meaning a costly and time-consuming repair job. The best
          way to treat the hard water problem is by softening your water before
          it has a chance to build up.
          <br />
          <br />
          <span style={{ fontWeight: 600, fontSize: 22 }}>Appliances</span>:
          Using a water softener can prevent the adverse effect of hard water on
          your dishwasher and laundry machines. Not only does scale buildup
          lower the quality and efficiency due to hard water composition, but
          scale inside the appliance results in a shorter lifespan for your
          coffee machines, ice makers, and even water heaters.
          <br />
          <br />
          <span style={{ fontWeight: 600, fontSize: 22 }}>
            Gas or electric bills
          </span>
          : As : A water softener can lower your gas or electric bill by
          preventing pipe damage. When scale builds inside a pipe, the space
          available for water to pass through narrows. As a result, the water
          pressure must increase for water to push through. The pipe narrowing
          also causes a failure to efficiently transfer heat, forcing you to run
          your water heater higher to compensate. Both issues result in an
          expensive gas or electric bill.
          <br />
          <br />
          <span style={{ fontWeight: 600, fontSize: 22 }}>
            Soap and detergents
          </span>
          : Soft water penetrates and dissolves better with soap, meaning more
          suds and a deeper lather for your body, clothes, and dishes. Hard
          water uses twice the amount of cleaning solution, to achieve the same
          amount of suds, as a soft water home. With a water softener, you can
          save hot water by using cold water for laundry. Hot water is often
          used to remove mineral deposits and better dissolve detergents. This
          is unnecessary with a water softener system. Washing with cold water
          will also keep your clothes from shrinking.
          <br />
          <br />
          <span style={{ fontWeight: 600, fontSize: 22 }}>
            Cleaner hair and softer skin
          </span>
          <br />
          <br />
          Soft water can be extremely beneficial for your hair and skin while
          bathing or showering. The mineral ions in hard water prevent it from
          being completely soluble with soaps, forming a precipitate in the form
          of soap scum. Because soft water lacks these minerals, homes with a
          water softener enjoy a deeper lather. The benefits of a water softener
          on the skin go deeper than soap lather. It can also alleviate the
          effects hard water has on your body because of a loss of natural oils
          in your skin and hair.
          <br />
          <br /> Effects of soft water on skin: Because soft water contains
          fewer minerals, your skin picks up and holds moisture easier. While
          bathing or showering in hard water can have an adverse effect on your
          skin. The minerals in hard water remove the skin’s natural oils, which
          can dry out your skin and, for some, result in itchy, irritated skin.
          <br />
          <br />
          Effects of soft water on hair: Soft water can help balance your hair’s
          pH level, while hard water can cause your hair to feel dry, brittle,
          and frizzy. It can also dull your hair color. <br />
          <br />
          As scale buildup from hard water in your pipes increases, the water
          pressure from your shower decreases. Low water pressure does little to
          help you rinse the soap off your body or shampoo and conditioner from
          your hair.
          <br />
          <br />
          <span style={{ fontWeight: 600, fontSize: 22 }}>
            Brighter and softer clothes
          </span>
          <br />
          <br />
          A water softener prevents the adverse effects of hard water on your
          clothes while making them soft to the touch and preserving the new,
          fresh look and feel.
          <br />
          <br /> Brighter clothes: If your home has hard water, you're washing
          clothes in minerals that leave deposits. Over time, the minerals will
          cause the colors to fade. Some minerals can even cause stains or dingy
          whites. Soft water is the better option. In fact, many add salt to a
          load of colors to prevent bleeding, which isn't necessary for a soft
          water system and the use of sodium exchange. <br />
          <br />
          Cleaner clothes: Soft water dissolves into clothes easier, cleaning
          the clothes more effectively. With hard water, your clothes are being
          washed in minerals that leave deposits in the fabric. Over time, the
          minerals will cause the colors to fade and whites to become dingy.
          Because soft water dissolves detergent more effectively, you can use
          less detergent and may not need fabric softeners at all. <br />
          <br />
          <span style={{ fontWeight: 600, fontSize: 22 }}>Cleaner Dishes</span>
          <br />
          <br />
          If you live in a hard water area, you know how difficult it is to keep
          your dishes clean. No matter how many times you clean them, or the
          soaps or detergents you use, your glass and silverware are left with a
          cloudy appearance as soon as it dries. A water softener fixes the
          problem at the root, removing the minerals before they can build up on
          your dishes. And because soft water fuses with soaps and detergent
          more completely, there is more lather and more cleaning action in your
          dishwashing routine.
          <br />
          <br />
          <span style={{ fontWeight: 600, fontSize: 22 }}>
            {' '}
            Less time cleaning
          </span>
          <br />
          <br />
          If you live in a home with hard water, you know how time-consuming
          cleaning can be. You are constantly re-washing dishes and laundry. You
          may spend hours a week scrubbing chalky lime and soap scum off the
          walls of your showers, sinks, and faucets. Using a water softener not
          only prevent the negative effects of hard water, but soft water fully
          dissolves and penetrates soap, less insoluble soap scum or curd
          collects in your bathroom. Saving the time you spend cleaning on a
          regular basis.
        </div>
      </div>
    </div>
  )
}

export default WaterPopup
