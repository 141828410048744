import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'

const CarouselItem = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  }

  const CustomLeftArrow = ({ onClick, ...rest }) => {
    const {
      onMove,
      carouselState: { currentSlide, deviceType },
    } = rest

    return (
      <img
        src="/Vectorless.png"
        alt=""
        style={{
          margin: 0,
          position: 'absolute',
          left: -20,
          cursor: 'pointer',
        }}
        onClick={onClick}
      />
    )
  }

  const CustomRightArrow = ({ onClick, ...rest }) => {
    const {
      onMove,
      carouselState: { currentSlide, deviceType },
    } = rest

    return (
      <img
        src="/vectorgreater.png"
        alt=""
        onClick={onClick}
        style={{
          margin: 0,
          right: -20,
          position: 'absolute',
          cursor: 'pointer',
        }}
      />
    )
  }

  return (
    <div
      style={{
        margin: '40px auto 100px auto',
        maxWidth: 1060,
        position: 'relative',
        // border: '5px solid red',
      }}
    >
      <Carousel
        customRightArrow={<CustomRightArrow />}
        customLeftArrow={<CustomLeftArrow />}
        responsive={responsive}
      >
        {/* <div className="testimonial-card">
          <div
            style={{
              fontWeight: 500,
              fontSize: 20,
              lineHeight: 1.2,
              marginBottom: 20,
              maxWidth: 440,
              marginLeft: 'auto',
              marginRight: 'auto',
              // border: '1px solid red',
              height: 140,
            }}
          >
            This icon has it all. It’s been the perfect accompaniment to our
            productivity app brand.
          </div>
          <div
            style={{
              display: 'flex',
              width: 300,
              // margin: '37px auto 15px auto',
              justifyContent: 'center',
              // border: '1px solid blue',
            }}
          >
            <div>
              <img src="/Ellipse 13.png" alt="" width="55px" />
            </div>
            <div
              style={{
                paddingLeft: 18,
                textAlign: 'left',
              }}
            >
              <div style={{ fontSize: 14, fontWeight: 600 }}>Shivakumar HE</div>
              <div style={{ fontSize: 13, paddingTop: 8 }}>
                Splash Auto Spa , Hebbagodi
              </div>
            </div>
          </div>
        </div> */}

        <div className="testimonial-card" style={{}}>
          <div
            style={{
              fontWeight: 500,
              fontSize: 20,
              lineHeight: 1.2,
              marginBottom: 20,
              maxWidth: 440,
              marginLeft: 'auto',
              marginRight: 'auto',
              padding: '20px 5px',
              // border: '1px solid red',
              height: 140,
            }}
          >
            If you are looking for heat pump go for Phoenix Thermal Solution.
            They are the best in market with good service and comparing price i
            got the best deal from them.
          </div>
          <div
            style={{
              display: 'flex',
              width: 260,
              justifyContent: 'center',
              // border: '1px solid blue',
            }}
          >
            <div>
              <img src="/deepak-img.png" alt="" width="55px" />
            </div>
            <div
              style={{
                paddingLeft: 18,
                textAlign: 'left',
              }}
            >
              <div style={{ fontSize: 14, fontWeight: 600 }}>Deepak</div>
              <div style={{ fontSize: 13, paddingTop: 8 }}>
                Residential Owner
              </div>
            </div>
          </div>
        </div>
        <div className="testimonial-card">
          <div
            style={{
              fontWeight: 500,
              fontSize: 20,
              lineHeight: 1.2,
              marginBottom: 20,
              maxWidth: 440,
              marginLeft: 'auto',
              marginRight: 'auto',
              padding: '20px 5px',
              // border: '1px solid red',
              height: 140,
            }}
          >
            I would say one stop solution for hot water or Solar in your house.
            I loved their service and quality is the at most. It’s been 1 year
            now I haven’t faced any major issues. They provide timely services.
          </div>
          <div
            style={{
              display: 'flex',
              width: 260,
              //
              justifyContent: 'center',
              // border: '1px solid blue',
            }}
          >
            <div>
              <img src="/niranjan-img.png" alt="" width="55px" />
            </div>
            <div
              style={{
                paddingLeft: 18,
                textAlign: 'left',
              }}
            >
              <div style={{ fontSize: 14, fontWeight: 600 }}>Niranjan</div>
              <div style={{ fontSize: 13, paddingTop: 8 }}>
                Residential Owner
              </div>
            </div>
          </div>
        </div>
        <div className="testimonial-card">
          <div
            style={{
              fontWeight: 500,
              fontSize: 19,
              lineHeight: 1.2,
              marginBottom: 20,
              maxWidth: 440,
              marginLeft: 'auto',
              marginRight: 'auto',
              padding: '20px 5px',
              // border: '1px solid red',
              height: 140,
            }}
          >
            I Ordered a Pressure Pump from Phoenix thermal Solutions, The
            quality of product is good and i have got a nice response from the
            company I'm recommending people to buy their products to install in
            your home.
          </div>
          <div
            style={{
              display: 'flex',
              width: 260,
              //
              justifyContent: 'center',
              // border: '1px solid blue',
            }}
          >
            <div>
              <img src="/prithvi-img.png" alt="" width="55px" />
            </div>
            <div
              style={{
                paddingLeft: 18,
                textAlign: 'left',
              }}
            >
              <div style={{ fontSize: 14, fontWeight: 600 }}>Prithvi</div>
              <div style={{ fontSize: 13, paddingTop: 8 }}>Business Owner</div>
            </div>
          </div>
        </div>
      </Carousel>
    </div>
  )
}

export default CarouselItem
