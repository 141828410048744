const Footer = () => {
  return (
    <>
      <div
        style={{
          maxWidth: '75%',
          margin: '0px auto 20px auto',
          background: '#393939',
          borderRadius: 30,
          minHeight: 290,
          paddingBottom: 30,
          paddingLeft: 10,
          paddingRight: 10,
          minWidth: 300,
        }}
      >
        <div
          style={{
            color: 'white',
            textAlign: 'center',
            fontWeight: 800,
            fontSize: 40,
            padding: '60px 0 30px 0',
          }}
        >
          Share your contact
        </div>
        <div
          style={{
            display: 'flex',
            maxWidth: 1200,
            flexWrap: 'wrap',
            // justifyContent: 'center',
            margin: '10px auto 20px',
            justifyContent: 'space-evenly',
          }}
        >
          <div
            style={{
              maxWidth: 340,
              marginTop: 26,
              marginLeft: 5,
            }}
          >
            <input
              type="text"
              placeholder="Name"
              style={{
                border: '1px solid #888888',
                marginBottom: 10,
                padding: '10px 15px',
                borderRadius: 12,
                background: '#393939',
                minWidth: 280,
                height: 36,
              }}
            />
            <input
              type="text"
              placeholder="Mobile"
              style={{
                border: '1px solid #888888',
                color: '#888888',
                marginTop: 20,
                padding: '10px 15px',
                borderRadius: 12,
                background: '#393939',
                minWidth: 280,
                height: 36,
              }}
            />
          </div>
          <textarea
            style={{
              border: '1px solid #888888',
              margin: '26px 10px 0px 10px',
              // height: "10px",
              borderRadius: 12,
              paddingLeft: 20,
              paddingTop: 15,
              background: '#393939',
              color: '#888888',
              width: 300,
              flex: 2,
              maxWidth: 400,
              minWidth: 280,
              minHeight: 130,
            }}
          >
            Message
          </textarea>

          <button
            className="send-inquiry"
            // onSubmit={}
            style={{
              color: 'white',
              minWidth: '280px',
              maxWidth: 450,
              background: '#FF8966',
              borderRadius: 12,
              padding: '16px 20px',
              textAlign: 'center',
              border: 0,
              alignSelf: 'flex-end',
              marginTop: 26,
              fontSize: 20,
              cursor: 'pointer',
            }}
          >
            Send Enquiry
          </button>
        </div>
      </div>

      {/* -------------------Footer---------- */}
      <div style={{ background: '#1E1E1E', marginTop: 90 }}>
        <div
          style={{
            maxWidth: 1150,
            margin: 'auto',
            // height: 350,
            display: 'flex',
          }}
        >
          <div
            style={{
              width: '49.5%',
              marginTop: 45,
              // border: '1px solid yellow',
            }}
          >
            <div>
              <img
                src="phoenix-logo.png"
                alt="img"
                width={120}
                style={{ marginRight: 5 }}
              />
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              width: '49.5%',
              marginLeft: 10,
              marginTop: 45,
            }}
          >
            <div style={{ color: '#fff', width: '50%' }}>
              <div>Address</div>
              <div style={{ color: '#888888', margin: '15px 0px' }}>
                #10, 11th Cross, Ganapathi nagar, Peenya Indl. Area, 4th Phase,
                Bangalore 560058
              </div>
              <a
                href="https://goo.gl/maps/6kdgNuNXSBiUQjY46"
                target="_blank"
                style={{ textDecoration: 'none' }}
                rel="noreferrer"
              >
                <div
                  style={{ color: '#FF8966', marginTop: 5, cursor: 'pointer' }}
                >
                  View on Map
                </div>
              </a>
            </div>
            <div
              style={{
                width: '50%',
                textAlign: 'center',
              }}
            >
              <div style={{ color: '#fff' }}>Contact</div>
              <div style={{ color: '#888888', marginTop: 15 }}>
                +91 99161 34539
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            height: 120,
            margin: '30px auto 0px auto',
            maxWidth: 1150,
          }}
        >
          <hr
            style={{
              margin: 'auto',
              opacity: '0.2',
            }}
          />
          <div
            style={{
              display: 'flex',
              marginTop: 28,
              color: '#888888',
              justifyContent: 'space-between',
            }}
          >
            <div style={{ fontSize: 18 }}>All Rights Reserved.</div>
            <div style={{ display: 'flex' }}>
              <a
                href="https://www.facebook.com/people/Mahindra-BV/100089743836669/"
                style={{ textDecoration: 'none' }}
                target="_blank"
                rel="noreferrer"
              >
                <div
                  style={{
                    color: '#1778F2',
                    marginRight: 30,
                    fontSize: 18,
                    fontWeight: 700,
                    cursor: 'pointer',
                  }}
                >
                  Facebook
                </div>
              </a>

              <a
                href="https://www.instagram.com/phoenixthermalsolution/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="mask-group.png"
                  alt=""
                  width={85}
                  style={{ border: '0px solid red', cursor: 'pointer' }}
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Footer
