// import './HeatPumpsPopup.css'

import { useEffect, useRef, useState } from 'react'

const HeatPumpsPopup = ({ toggleFunction }) => {
  let menuRef = useRef()

  useEffect(() => {
    let handler = (e) => {
      console.log('e.target contains', e.target)

      if (menuRef.current && !menuRef.current.contains(e.target)) {
        console.log('Clicked outside the component')
        toggleFunction(false)
      }
    }

    document.addEventListener('mousedown', handler)

    return () => {
      document.removeEventListener('mousedown', handler)
    }
  })

  return (
    <div
      style={{
        maxWidth: '100vw',
        height: '100vh',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        position: 'fixed',
        background: 'rgba(49, 49, 49, 0.8)',
        zIndex: 0,
      }}
    >
      <div
        ref={menuRef}
        style={{
          position: 'absolute',
          background: 'white',
          borderRadius: 10,
          margin: 'auto',
          maxWidth: '85%',
          left: '0',
          right: '0',
          top: 50,
          bottom: 30,
          overflowY: 'scroll',
        }}
      >
        <div
          style={{
            maxWidth: '92%',
            margin: '5px auto 10px auto',
            // overflowY: 'scroll',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              margin: '35px 0px 15px 0px',
              color: '#252521',
              fontSize: 29,
              fontWeight: 700,
            }}
          >
            <div className="">Heatpumps</div>
            <div
              onClick={() => {
                toggleFunction(false)
              }}
            >
              X
            </div>
          </div>

          <div
            style={{
              fontSize: 21.5,
              margin: '25px 00px 25px 00px',
              lineHeight: 1.8,
            }}
          >
            Matching pace with a velocity of change, row shift to renewable
            energy with our versatile product line and services.
            <br /> Heat Pump Water Heaters provide a very suitable and efficient
            alternative to the regular water heater. Here are a few benefits of
            using Heat Pump Water Heaters- <br />
            It can heat up large volume of water at a time. They come in
            different varieties having capacities upto 200 Litres to MORE.
            <br /> These are highly cost effective as they consume lesser
            electricity to heat up the same volume of water than a regular water
            heater.
            <br /> They do provide service for a long time.
            <br /> Meaning? They have a long life. They can act as coolers as
            well as they tend to cool up the place where they are installed.
            <br /> Heat Pump Water Heaters are absolutely safe.
            <br /> The installation requires little effort. Also they are easy
            to maintain.
          </div>

          <div
            style={{
              fontSize: 24,
              fontWeight: 800,
              color: '#252521',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              margin: '120px 0px 95px 0px',
            }}
          >
            <div
              style={{
                border: '0px solid red',
                marginBottom: 80,
                fontSize: 28,
                marginLeft: 30,
              }}
            >
              Why Heatpumps over Geyser
            </div>
            <img src="/group-26.png" alt="" />
          </div>

          {/* <div
            style={{
              color: '#252521',
              fontWeight: 700,
              fontSize: 26,
              marginTop: 80,
              marginBottom: 60,
            }}
          >
            Why Heatpumps over Geyser
          </div>
          <div
            style={{
              // border: '1px solid blue',
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center',
              marginTop: 50,
            }}
          >
            <div
              style={{
                // border: '2px solid red',
                maxWidth: '360px',
                width: '100%',
                // minWidth: 200,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                margin: '18px 0px',
              }}
            >
              <div
                style={{
                  weight: 500,
                  justifyContent: 'center',
                  fontSize: 20,
                  marginBottom: 25,
                  paddingLeft: 40,
                }}
              >
                Input Power ={' '}
                <span style={{ color: '000', fontWeight: 'bold' }}>8 kw</span>
              </div>
              <div
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  margin: '20px 0px',
                }}
              >
                <img
                  className="geyser img"
                  src="/machine.png"
                  alt=""
                  style={{ border: '0px solid blue', width: '50%' }}
                />
                <img
                  className="geyser img"
                  src="/machine.png"
                  alt=""
                  style={{ border: '0px solid blue', width: '50%' }}
                />
                <img
                  className="geyser img"
                  src="/machine.png"
                  style={{ border: '0px solid blue', width: '50%' }}
                  alt=""
                />
                <img
                  className="geyser img"
                  src="/machine.png"
                  alt=""
                  style={{ border: '0px solid blue', width: '50%' }}
                />
              </div>

              <div
                style={{
                  weight: 500,
                  fontSize: 20,
                  textAlign: 'center',
                  marginTop: 25,
                }}
              >
                100 Litres Hot water =
                <span style={{ color: '000', fontWeight: 'bold' }}>
                  {' '}
                  4 Kwh{' '}
                </span>{' '}
                (Units)
              </div>
            </div>

            <div
              style={{
                // border: '1px solid orange',
                maxWidth: '360px',
                // minWidth: 200,
                width: '100%',
                marginLeft: 15,
                alignItems: 'center',
                // margin: 'auto',
                margin: '18px 0px',
                justifyContent: 'center',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <div
                style={{ width: 102, height: 23, background: '#333333' }}
              ></div>
              <div
                style={{ margin: '10px 0px', color: '#333333', fontSize: 20 }}
              >
                Equals
              </div>
              <div
                style={{
                  width: 102,
                  height: 23,
                  background: '#333333',
                  marginBottom: 35,
                }}
              ></div>
              <div style={{ fontWeight: 700, fontSize: 20 }}>4: 1</div>{' '}
              <div style={{ fontWeight: 700, fontSize: 22 }}>
                Power Consumption
              </div>
            </div>

            <div
              style={{
                // border: '4px solid pink',
                maxWidth: '360px',
                margin: '18px 0px',
                // minWidth: 200,
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                // margin: '0  auto  0 15px',
              }}
            >
              <div
                style={{
                  weight: 500,
                  fontSize: 20,
                  textAlign: 'center',
                  marginBottom: 25,
                }}
              >
                <div>
                  Input Power =
                  <span style={{ color: '000', fontWeight: 'bold' }}>
                    {' '}
                    1.75 kw
                  </span>{' '}
                </div>
              </div>

              <div
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  margin: 'auto',
                }}
              >
                <img
                  src="/cooler.png"
                  alt=""
                  style={{ justifyContent: 'center', border: 'px solid blue' }}
                />
              </div>

              <div
                style={{
                  weight: 500,
                  fontSize: 20,
                  textAlign: 'center',
                  marginTop: 25,
                }}
              >
                100 Litres Hot water =
                <span style={{ color: '000', fontWeight: 'bold' }}>
                  {' '}
                  4 Kwh{' '}
                </span>{' '}
                (Units)
              </div>
            </div>
          </div> */}

          {/* -----------images--------------- */}
          <div
            style={{
              fontSize: 24,
              fontWeight: 800,
              color: '#252521',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              margin: '120px 0px 95px 0px',
            }}
          >
            <div
              style={{
                border: '0px solid red',
                marginBottom: 80,
                fontSize: 28,
                marginLeft: 30,
              }}
            >
              How Heatpump works ?
            </div>
            <img src="/heatpump1.png" alt="" />
          </div>

          {/* --------------Text ------------- */}
          <div
            style={{
              color: '#252521',
              fontSize: 24,
              lineHeight: 1.8,
              fontWeight: 400,
              marginBottom: 100,
            }}
          >
            <div
              style={{
                fontSize: 28,
                fontWeight: 800,
                marginBottom: 55,
              }}
            >
              Advantages of heat pump
            </div>
            In the last few decades, technological advancement has reached its
            peak, and is continuing to make much larger strides in order to make
            life easier for all of us. Now, there’s a device for almost
            everything you might need. You can heat your room, search for any
            information that you want on a device, can operate your home
            lighting etc.
            <br />
            <br />
            <br /> One such device is the water heater, also known as the
            geyser. There are different types of geysers, and the most efficient
            of which has to be the heat pump. What is a heat pump water heater?
            Basically, it’s a type of water heater that uses the heat in the air
            to heat the water. This is more efficient than traditional water
            heaters as the heat pump uses the available heat in the air to heat
            the water, while traditional water heaters generate heat by using
            electricity. The efficiency of the heat pump is one of its most
            significant advantages.
            <br />
            <br /> Now that you know what a heat pump is, it is important to
            know that there are different types of heat pump that are available.
            Let’s take a quick look at what they are.
            <br />
            <br /> Air Source Heat Pump: An air source heat pump uses the heat
            in the air to provide heating solutions. This is the most commonly
            used of all types of heat pump.
            <br />
            <br /> Water Source Heat Pump: A water source heat pump uses water
            to dissipate the heat. Because of this, using a water source heat
            pump requires access to a well, lake, or other water body. The water
            source heat pump system is, therefore, not as common.
            <br />
            <br /> Ground Source Heat Pump: A ground source heat pump uses the
            ground to dissipate the heat. Using a ground source heat pump means
            that there is a lot of effort required in drilling the ground and
            setting up the heat pump system, and therefore this is not as common
            either.
            <br />
            <br /> Now that you know what a heat pump is, and the different
            types of heat pumps that exist, let’s look at some of the benefits
            of having a heat pump system in your bathroom.
            <br />
            <br />
            <div style={{ fontWeight: 700, fontSize: 26 }}>
              Highly efficient
            </div>
            <br />
            The foremost advantage of using a heat pump is its significantly
            higher efficiency over the other forms of water heaters that are in
            the market. All other types of heaters use electricity to generate
            all the heat that is needed to heat the water. But a heat pump uses
            the heat in the air, and transfers it into the water.
            <br />
            <br /> This results in significant saving of power, which can be
            used for other purposes. In fact, it is said that heat pumps are the
            latest bathroom tech innovation, and can be upto four times more
            efficient than other heating solutions!
            <br />
            <br />
            <span style={{ fontWeight: 700, fontSize: 26 }}>
              They can act as coolers as well
            </span>
            <br />
            <br />
            If you know what a heat pump is, then you probably know that it uses
            the heat in the air to work as a water heater. But there’s another
            side to this coin as well. Since the heat is being removed from the
            air, the heat pump can also act as a cooler to the areas in which it
            is installed!
            <br />
            <br /> With a heat pump, it’s like you’re getting two devices in
            one. You can both heat your water and cool your room at the same
            time, using no extra power!
            <br />
            <br /> <div>They can heat a high volume of water at a time</div>
            What you probably don’t know about a heat pump is the amount of
            water that it can heat in one sitting. Heat pump water heaters come
            with storage capacities of at least 200 Litres! They can often heat
            more than that in one sitting, depending on the capacity of the heat
            pump that you buy.
            <br />
            <br /> This, coupled with the heat pump’s efficiency make it an
            irresistible choice for your home.
            <br />
            <br />
            <span style={{ fontWeight: 700, fontSize: 26 }}>
              Ease of installation and maintenance
            </span>
            <br />
            <br />
            Another big advantage of buying a heat pump for your bathroom is
            that heat pumps are relatively easy to install and maintain.
            Installation of heat pumps can be done very quickly, and since the
            system uses the ambient heat in the air to heat the water, it uses a
            lot less power. What this means is that a heat pump is going to be
            much easier to maintain, and will last longer than other types of
            heating systems as well.
            <br />
            <br />
            <span style={{ fontWeight: 700, fontSize: 26 }}>
              Minimal noise while operating
            </span>
            <br />
            <br />
            Another advantage of using a heat pump is that it is designed to
            make minimal noise while operating. This again leads back to the
            efficiency of the heat pump in its operation.
            <br />
            <br /> Since the heat pump uses minimal energy to heat the water,
            there is very little noise and heat that is generated from the
            machine. So, if you’re looking to replace your old heater with a new
            one, it would make a lot of sense for you to go with a more
            efficient, less noisy system.
            <br />
            <br />
            <span style={{ fontWeight: 700, fontSize: 26 }}>
              All weather comfort
            </span>
            <br />
            <br />
            One more advantage of using a heat pump is that it can be used in
            all weather conditions. It doesn’t necessarily have to be summer
            when you can use the heat pump. The heat pump needs only minimal
            energy to operate. <br />
            <br />
            This is one of the bigger advantages of using a heat pump. Since it
            can be used all year round, and it operates very efficiently, it is
            an excellent investment for most people.
            <br />
            <br />
            <span style={{ fontWeight: 700, fontSize: 26 }}>Eco Friendly</span>
            <br />
            <br />
            One of the biggest advantages of the heat pump is that because it is
            highly efficient, it is very eco friendly as well. The heat pump
            works by using the heat in the atmosphere and transferring it to the
            water, and requires only a limited amount of power to run.
            <br />
            <br />
            Therefore, if you’re conscious about the environment, a heat pump is
            the way to go for you! <br />
            <br />
            <span style={{ fontWeight: 700, fontSize: 26 }}>Durability</span>
            <br />
            <br />
            Another advantage of using the heat pump is that because it is very
            efficient, it is also extremely durable. The maintenance of the heat
            pump is also very easy, and doesn’t take much effort as well.
            <br />
            <br />
            Because of this, what you’re getting is a highly efficient, long
            lasting, noiseless water heaters that can double up as room coolers
            as well!
            <br />
            <br /> From the above points, it can clearly be seen that heat pumps
            are the future of water heating solutions. While heat pumps might be
            expensive to purchase and install, the savings you make in terms of
            power will outweigh the cost at one point. So, what are you waiting
            for?
          </div>
        </div>
      </div>
    </div>
  )
}

export default HeatPumpsPopup
