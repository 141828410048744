import React, { useState } from 'react'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import GalleryViewAll from './GalleryViewAll'

const Gallery = () => {
  const [show, setShow] = React.useState(false)

  const handleLinkClick = (event) => {
    event.preventDefault()
    window.open('https://drive.google.com/drive/folders/1_wGVKgid8jxtAdjVg3t-hPbemaFUnRnW', '_blank')
  }

  const toggleFunction = (show) => {
    setShow(show)
  }

  return (
    <div
      style={{
        margin: '140px auto 160px auto',
        // border: '1px solid red',
        textAlign: 'center',
        color: '#656C78',
        fontWeight: 600,
        letterSpacing: 3,
        fontSize: 14,
      }}
    >
      GALLERY
      <div
        style={{
          fontSize: 36,
          fontWeight: 700,
          color: '#252521',
          // border: '1px solid red',
          letterSpacing: 0,
          margin: '20px auto 70px auto',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-start',
        }}
      >
        <div
          style={{
            justifyContent: 'center',
            flex: 1,
            // border: '1px solid blue',
          }}
        >
          Few glimpses of our work
        </div>
        <div
         onClick={handleLinkClick}
          style={{
            fontSize: 16,
            color: '#FF8966',
            border: '1px solid #FF8966',
            borderRadius: 15,
            padding: '12px 35px',
            fontWeight: 700,
            marginRight: 20,
            cursor: 'pointer',
          }}
          className="display-none"
        >
          View All
        </div>

        {show && <GalleryViewAll toggleFunction={toggleFunction} />}
      </div>
      <div style={{ position: 'relative', marginBottom: 70 }}>
        <Carousel
          arrows={false}
          responsive={responsive}
          showDots
          removeArrowOnDeviceType={['tablet', 'mobile']}
          // customDot={<CustomDot />}
        >
          <img
            className="gallery-img"
            src="/image-32.png"
            alt=""
            style={{ marginRight: 20 }}
          />

          <img
            className="gallery-img"
            src="/gallery-img1.png"
            alt=""
            style={{ marginRight: 20 }}
          />
          <img
            className="gallery-img"
            src="/gallery-imgs3.png"
            alt=""
            style={{ marginRight: 20 }}
          />
          <img
            className="gallery-img"
            src="/gallery-imgs5.png"
            alt=""
            style={{ marginRight: 20 }}
          />
          <img
            className="gallery-img"
            src="/gallery-imgs7.png"
            alt=""
            style={{ marginRight: 20 }}
          />
          <img
            className="gallery-img"
            src="/gallery-imgs4.png"
            alt=""
            style={{ marginRight: 20 }}
          />

          <img
            className="gallery-img"
            src="/gallery-img61.png"
            alt=""
            style={{ marginRight: 20 }}
          />
          <img
            className="gallery-img"
            src="/image-55.png"
            alt=""
            style={{ marginRight: 20 }}
          />
          <img
            className="gallery-img"
            src="/image-2.png"
            alt=""
            style={{ marginRight: 20 }}
          />
          <img
            className="gallery-img"
            src="/image-3.png"
            alt=""
            style={{ marginRight: 20 }}
          />
          <img
            className="gallery-img"
            src="/image-6.png"
            alt=""
            style={{ marginRight: 20 }}
          />
          <img
            className="gallery-img"
            src="/image-7.png"
            alt=""
            style={{ marginRight: 20 }}
          />
          <img
            className="gallery-img"
            src="/gallery-img2.png"
            alt=""
            style={{ marginRight: 20 }}
          />
          <img
            className="gallery-img"
            src="/gallery-imgs8.png"
            alt=""
            style={{ marginRight: 20 }}
          />
          <img
            className="gallery-img"
            src="/image-8.png"
            alt=""
            style={{ marginRight: 20 }}
          />
        </Carousel>
      </div>
      <div
        onClick={handleLinkClick}
        style={{
          fontSize: 16,
          color: '#FF8966',
          border: '1px solid #FF8966',
          borderRadius: 15,
          padding: '12px 35px',
          fontWeight: 700,
          marginRight: 20,
        }}
        className="display-block"
      >
        View All
      </div>
    </div>
  )
}

export default Gallery

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 2,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 1, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
}
