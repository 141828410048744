const AboutUs = () => {
  return (
    <>
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
        }}
        className="about-container center-container"
      >
        <div
          style={{
            // margin: 'auto',
            // paddingLeft: 12,
            flex: 1,
            minWidth: 310,
            // width: 440,
            maxWidth: 510,
          }}
        >
          <div style={{ marginBottom: 20, letterSpacing: 3, fontSize: 14 }}>
            WHO ARE WE
          </div>

          <h1 style={{ marginBottom: 30, color: '#252521', fontWeight: 800 }}>
            About Us.
          </h1>

          <div style={{ marginBottom: 30, lineHeight: 1.5, fontSize: 16 }}>
            At Phoenix Thermal Solutions, we're your one-stop-shop for all your
            renewable energy and hot water needs. We specialise in offering
            customised and integrated solutions for heat pumps, solar heaters,
            booster pumps, and softeners in diverse segments.
          </div>
          <div style={{ marginBottom: 30, lineHeight: 1.5, fontSize: 16 }}>
            With our advanced heat pump technology, we can help you achieve
            optimal energy efficiency while significantly reducing your carbon
            footprint.
          </div>
          <div style={{ marginBottom: 30, lineHeight: 1.5, fontSize: 16 }}>
            Whether you're a homeowner, business owner, or industrial leader, we
            have the expertise and experience to deliver hot water solutions
            that work for you - from <strong>100 liters per day </strong>to
            <strong> 6.5 lakh liters per day</strong> in a single site.
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            position: 'relative',
          }}
        >
          <div
            className="about-exp"
            style={{
              position: 'absolute',
              top: 40,
              left: -50,
              background: '#393939',
              borderRadius: 25,
              color: 'white',
              padding: '15px 25px 15px 25px',
              fontWeight: 800,
              fontSize: 20,
              textAlign: 'center',
            }}
          >
            <div>10 Years</div>
            <div
              style={{
                fontWeight: 400,
                fontSize: 15,
                width: 115,
                marginTop: 5,
                letterSpacing: 1.2,
              }}
            >
              of experience
            </div>
          </div>

          <img src="/Rectangle 41.png" alt="img" className="pump-img" />
        </div>
      </div>

      {/* ----------Our Commitent Content----------- */}
      <div
        style={{
          background: '#393939',
          margin: '0px 0px 50px 0px ',
          borderRadius: '20px',
          marginBottom: 40,
          color: 'white',
          paddingBottom: 50,
        }}
      >
        <div
          style={{
            width: '85%',
            margin: 'auto',
            paddingTop: 45,
          }}
        >
          <div style={{ fontWeight: 800, marginBottom: 22, fontSize: 22 }}>
            Our Commitment to you
          </div>
          <div style={{ fontSize: 14, fontWeight: '200' }}>
            At Phoenix Thermal Solutions, we're dedicated to providing
            high-quality and sustainable hot water solutions. Here are
            <br /> just a few of the reasons why our customers trust us:
          </div>
        </div>

        <div
          style={{
            width: '90%',
            margin: 'auto',
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-evenly',
          }}
        >
          <div style={{ marginTop: 55, maxWidth: 320 }}>
            <img src="Ellipse.png" alt="img" />
            <img src="Ellipse.png" alt="img" style={{ marginLeft: -14 }} />
            <h3> Innovative & Customisable</h3>
            <div>
              Innovative and customisable designs to meet your unique needs.
            </div>
          </div>
          <div style={{ marginTop: 55, maxWidth: 320 }}>
            <img src="Ellipse.png" alt="img" />
            <img src="Ellipse.png" alt="img" style={{ marginLeft: -14 }} />
            <h3> Dependable Products</h3>
            <div>
              Dependable products with a track record of reliability,
              durability, and top performance.
            </div>
          </div>
          <div style={{ marginTop: 55, maxWidth: 320 }}>
            <img src="Ellipse.png" alt="img" />
            <img src="Ellipse.png" alt="img" style={{ marginLeft: -14 }} />
            <h3> Easy Installation</h3>
            <div>
              Hassle-free installation and post-sale support from our
              knowledgeable team.
            </div>
          </div>

          {/* ---------- Table 2------------- */}

          <div style={{ marginTop: 55, maxWidth: 320 }}>
            <img src="Ellipse.png" alt="img" />
            <img src="Ellipse.png" alt="img" style={{ marginLeft: -14 }} />

            <h3> Tailored turnkey</h3>
            <div>
              Tailored and turnkey hot water solutions that are designed just
              for you.
            </div>
          </div>
          <div style={{ marginTop: 55, maxWidth: 320 }}>
            <img src="Ellipse.png" alt="img" />
            <img src="Ellipse.png" alt="img" style={{ marginLeft: -14 }} />
            <h3>Quality-focused</h3>
            <div>
              Quality-focused in every aspect of our business, from design to
              delivery.
            </div>
          </div>
          <div style={{ marginTop: 55, maxWidth: 320 }}>
            <img src="Ellipse.png" alt="img" />
            <img src="Ellipse.png" alt="img" style={{ marginLeft: -14 }} />
            <h3> Sustainability & Beyond</h3>
            <div>
              Committed to sustainability and reducing our environmental impact.
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AboutUs
