import React, { useEffect, useRef } from 'react'

const PhotoVoltaic = ({ toggleFunction }) => {
  let menuRef = useRef()

  useEffect(() => {
    let handler = (e) => {
      console.log('e.target contains', e.target)

      if (menuRef.current && !menuRef.current.contains(e.target)) {
        console.log('Clicked outside the component')
        toggleFunction(false)
      }
    }

    document.addEventListener('mousedown', handler)
  })

  return (
    <div
      style={{
        maxWidth: '100vw',
        height: '100vh',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        position: 'fixed',
        background: 'rgba(49, 49, 49, 0.8)',
      }}
    >
      <div
        ref={menuRef}
        style={{
          position: 'absolute',
          background: 'white',
          borderRadius: 10,
          margin: 'auto',
          maxWidth: '75%',
          left: '0',
          right: '0',
          top: 50,
          bottom: 50,
          overflowY: 'scroll',
        }}
      >
        <div
          style={{
            maxWidth: '92%',
            margin: '5px auto 60px auto',
            color: '#252521',
            fontWeight: 400,
            fontSize: 22,
            lineHeight: 1.8,
            // overflowY: 'scroll',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              margin: '35px 0px 15px 0px',
              color: '#252521',
              fontWeight: 700,
              fontSize: 22,
            }}
          >
            <div style={{ fontSize: 30 }}>Photo Voltaic</div>
            <div
              onClick={() => {
                toggleFunction(false)
              }}
            >
              X
            </div>
          </div>
          <div>
            Photovoltaics is the conversion of light into electricity using
            semiconducting materials that exhibit the photovoltaic effect.The
            photovoltaic effect is commercially used for electricity generation
            and as photosensors.
          </div>
          <br />
          <div style={{ fontWeight: 800, fontSize: 24 }}>
            How Does Photovoltaics Work?
          </div>
          <br />
          <div>
            Photovoltaic technology uses semiconductors to produce power based
            on the photoelectric effect.
          </div>
          <div style={{ fontSize: 22 }}>
            If photons of light (either natural or artificial) with enough
            energy penetrate a photovoltaic cell, they can excite electrons to a
            higher energy state. In fact, the electrons of a semiconductor that
            are not normally free, will become free and act just like they’re in
            a conducting material. These free electrons can then be captured,
            resulting in an electric current that can be used to produce
            electricity. The more light gets absorbed, the more electricity will
            be produced by photovoltaics. So by using the photovoltaic
            phenomenon, we can easily convert solar energy into electricity and
            use it as an alternative to traditional means of energy generation.
          </div>
          <br />
          <br />
          <div style={{ fontSize: 24, fontWeight: 800, lineHeight: 1.8 }}>
            Advantages of photovoltaic systems
            <br />
            <br />
          </div>
          <span style={{ fontWeight: 700, fontSize: 22 }}>
            High reliability
          </span>{' '}
          <br />
          <br />
          Photovoltaic systems are still highly reliable even under harsh
          conditions. Photovoltaic arrays ensure continuous, uninterrupted
          operation of critical power supplies. <br />
          <br />
          <span style={{ fontWeight: 700, fontSize: 22 }}>
            Strong persistence
          </span>{' '}
          <br />
          <br />
          Most modules in a PV system have a warranty period of up to 25 years
          and remain operational even after many years.
          <br />
          <br />
          <span style={{ fontWeight: 700, fontSize: 22 }}>
            Low maintenance costs
          </span>{' '}
          <br />
          <br />
          Photovoltaic systems require only regular inspections and occasional
          repairs, which are extremely low cost compared to conventional fuel
          systems. <br />
          <br />
          <span style={{ fontWeight: 700, fontSize: 22 }}>
            Zero fuel consumption
          </span>{' '}
          <br />
          <br />
          Photovoltaic systems do not require fuel and can eliminate associated
          procurement, storage and transportation costs.
          <br />
          <br />
          <span style={{ fontWeight: 700, fontSize: 22 }}>
            Noise pollution is small
          </span>{' '}
          <br />
          <br />
          The photovoltaic system can operate quietly with minimal mechanical
          movement.
          <br />
          <br />
          <span style={{ fontWeight: 700, fontSize: 22 }}>
            There is photovoltaic supervision
          </span>
          <br />
          <br />
          In order to improve energy efficiency, photovoltaic systems may need
          to add some modules.
          <br />
          <br />
          <span style={{ fontWeight: 700, fontSize: 22 }}>Strong security</span>
          <br />
          <br />
          Photovoltaic systems do not require fuel and can be safely operated
          after proper design and installation.
          <br />
          <br />
          <span style={{ fontWeight: 700, fontSize: 22 }}>
            Strong independence
          </span>
          <br />
          <br />
          The reason for adopting this new technology in many residential areas
          is that photovoltaic systems maintain the independence of energy
          production and are therefore unaffected by utilities.
          <br />
          <br />
        </div>
      </div>
    </div>
  )
}

export default PhotoVoltaic
