import React from 'react'
import './DealWith.css'
import PhotoVoltaic from './popups/PhotoVoltaic'

const DealWith = () => {
  const [show, setShow] = React.useState(false)

  const toggleFunction = (show) => {
    setShow(show)
  }
  return (
    <div
      style={{
        margin: '100px auto 30px auto',
      }}
      className="booster-container"
    >
      <div
        style={{
          textAlign: 'center',
          fontWeight: 700,
          fontSize: 32,
          marginBottom: 40,
          color: '#252521',
        }}
      >
        <h3>We also deal with</h3>
      </div>

      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-evenly',
          alignItems: 'center',
        }}
        className="center-container"
      >
        <div
          style={{
            margin: 18,
            maxWidth: 300,
          }}
        >
          <img
            src="/image-8.png"
            alt="1"
            style={{
              maxWidth: '290px',
              height: 'auto',
              margin: '10px',
              borderRadius: '15px',
              marginTop: 20,
            }}
          />
          <div style={{ color: '#252521', marginLeft: 12 }}>
            <div
              style={{
                fontWeight: 800,
                fontSize: 22,
                margin: '18px 0px',
              }}
            >
              Solar Heaters
            </div>
            <div
              style={{
                fontWeight: 400,
                fontSize: 16,
                color: '#252521',
                lineHeight: 1.4,
              }}
            >
              <input type="checkbox" id="check" />
              Solar water heating systems use panels or tubes, called solar
              collectors, to gather solar energy. The solar collectors convert
              the infra-red portion of visible light into heat. They are filled
              <span className="content">
                with a mix of water and glycol. This fluid is pumped round a
                circuit, which passes through the hot water cylinder.
              </span>
              <label
                for="check"
                className="label1"
                style={{ marginLeft: 5, fontSize: 14, cursor: 'pointer' }}
              >
                Read More
              </label>
            </div>
          </div>
        </div>

        <div
          style={{
            maxWidth: 300,
            margin: 18,
          }}
        >
          <img
            src="image-6.png"
            alt="2"
            style={{
              maxWidth: '290px',
              height: 'auto',
              margin: '10px',
              borderRadius: '15px',
              marginTop: 20,
            }}
          />
          <div style={{ color: '#252521', marginLeft: 12 }}>
            <div style={{ fontWeight: 800, fontSize: 22, margin: '18px 0px' }}>
              Photo Voltaic
            </div>
            <div
              style={{
                fontWeight: 400,
                fontSize: 16,
                color: '#252521',
                lineHeight: 1.4,
              }}
            >
              <input type="checkbox" id="check" />
              Photovoltaics is the conversion of light into electricity using
              semiconducting materials that exhibit the photovoltaic effect.The
              photovoltaic effect is commercially used for electricity
              generation and as.
              <span
                onClick={toggleFunction}
                style={{ color: '#FF8966', fontSize: 14, cursor: 'pointer' }}
              >
                Read More
              </span>
              {show && <PhotoVoltaic toggleFunction={toggleFunction} />}
            </div>
          </div>
        </div>

        <div
          style={{
            maxWidth: 300,
            margin: 18,
          }}
        >
          <img
            src="image-7.png"
            alt="3"
            style={{
              maxWidth: '290px',
              height: 'auto',
              margin: '10px',
              borderRadius: '15px',
              marginTop: 20,
            }}
          />
          <div style={{ color: '#252521', marginLeft: 12 }}>
            <div style={{ display: 'flex', margin: '18px 0px' }}>
              <div style={{ fontWeight: 800, fontSize: 22 }}>VRF</div>
              <span style={{ fontSize: 14, marginTop: 6, marginLeft: 5 }}>
                (Air Conditioning)
              </span>
            </div>

            <div
              style={{
                fontWeight: 400,
                fontSize: 16,
                color: '#252521',
                lineHeight: 1.4,
                maxWidth: 250,
              }}
            >
              <input type="checkbox" id="check2" />
              VRF (Variable Refrigerant Flow) AC systems are a type of air
              conditioning system that offer energy-efficient, flexible and
              precise temperature control. VRF AC systems use a .
              <span className="content2">
                single outdoor unit connected to multiple indoor units, allowing
                for individual control of temperature in different zones. This
                makes them ideal for commercial buildings, hotels, and other
                large-scale projects where different spaces require different
                levels of cooling or heating.
              </span>
              <label
                for="check2"
                className="label2"
                style={{ fontSize: 14, cursor: 'pointer' }}
              >
                {' '}
                Read More
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DealWith
