import React, { useEffect, useRef } from 'react'
import './Navbar.css'
import OurProducts from './OurProducts'
import { Link } from 'react-scroll'

const Banner = () => {
  const [windowSize, setWindowSize] = React.useState(window.innerWidth)

  const updateWindowSize = () => {
    setWindowSize(window.innerWidth)
    console.log('windiw size h', windowSize)
  }
  useEffect(() => {
    window.addEventListener('resize', updateWindowSize)
  }, [])

  const handleLinkClick = (event) => {
    event.preventDefault()
    window.open('https://api.whatsapp.com/send?phone=919916134539', '_blank')
  }

  return (
    <>
      <div className="main-background-img">
        <div
          className="nav"
          style={{
            color: 'white',
            width: '86%',
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            margin: '0px auto',
            paddingTop: 40,
          }}
        >
          <img src="/phoenix-logo.png" alt="img" style={{ width: 140 }} />

          <div
            className="nav-content"
            style={{
              display: 'flex',
              // marginTop: 2,
              color: 'white',
              fontSize: 14,
              alignItems: 'center',
              minWidth: 160,
              justifyContent: 'space-evenly',
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
              }}
            >
              <a href="mailto:mahindra@pheonixthermal.in?subject=Enquiry&body=">
                <img
                  src="/mail.png"
                  alt=""
                  style={{ width: 26 }}
                  target="_blank"
                />
              </a>

              <span style={{ marginLeft: 5 }} className="display-none">
                mahindra@pheonixthermal.in
              </span>
            </div>

            <div
              className="contact"
              style={{
                marginLeft: 18,
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
              }}
            >
              <img
                src="/whatsapp.png"
                alt=""
                style={{ width: 26, cursor: 'pointer' }}
                onClick={handleLinkClick}
              />
              <span style={{ marginLeft: 5 }} className="display-none">
                +919916134539
              </span>
            </div>
          </div>
        </div>

        <div
          style={{
            color: 'white',
            width: '85%',
            margin: 'auto',
            paddingBottom: 75,
          }}
        >
          <div
            style={{
              fontSize: 64,
              paddingTop: '0px',
              textAlign: 'left',
              fontWeight: '700',
              // fontFamily: 'jakart',
              lineHeight: 1.4,
            }}
            className="large-text"
          >
            Thermal Solutions
            <br /> For Future
          </div>
          <div
            className="matchingpace"
            style={{
              fontSize: 18,
              color: '#EBEBD8',
              marginTop: 20,
              maxWidth: 500,
              fontWeight: 400,
            }}
          >
            Matching pace with a velocity of change, row shift to renewable
            energy with our versatile product line and services.
          </div>
          <div
            style={{
              display: 'flex',
              marginTop: 35,
              flexWrap: 'wrap',
              justifyContent: 'space-between',
              maxWidth: 460,
            }}
          >
            <Link
              to="ourProduts"
              spy={true}
              smooth={true}
              offset={-70}
              duration={800}
            >
              <button
                style={{
                  cursor: 'pointer',
                  border: '1px solid white',
                  borderRadius: 12,
                  background: 'transparent',
                  width: 160,
                  paddingTop: 12,
                  paddingBottom: 12,
                  color: 'white',
                  fontSize: 17,
                  marginTop: 20,
                }}
              >
                View Products
              </button>
            </Link>
            <button
              onClick={handleLinkClick}
              style={{
                backgroundColor: '#FF8966',
                borderRadius: 12,
                padding: '10px 28px',
                color: 'white',
                border: 0,
                fontSize: 16,
                display: 'flex',
                alignItems: 'center',
                marginTop: 20,
                cursor: 'pointer',
              }}
            >
              <img
                src="/whatsapp.png"
                alt=""
                style={{ width: 22, marginRight: 7 }}
              />
              Send Enquiry on whatsapp
            </button>
          </div>
        </div>

        {/* --------------------SCORE CARD--------------- */}
        <div
          className="score-container"
          style={{
            position: 'absolute',
            borderRadius: 45,
            margin: 'auto',
            maxWidth: '75%',

            left: '0',
            right: '0',
            top: 640,
            backgroundColor: 'white',
            boxShadow: '0px 0px 5px 0.25px #616161',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              width: '80%',
              margin: '60px 5px 60px 5px',
              flexWrap: 'wrap',
              alignItems: 'center',
              justifyContent: 'space-between',
              textAlign: 'center',
            }}
            className="overlay-card-container"
          >
            <div style={{ marginBottom: 20 }}>
              <div className="count-num">350 +</div>
              <div className="count-text">Paying Guests Served</div>
            </div>
            <div style={{ marginBottom: 20 }} className="second-score-card">
              <div className="count-num">450+</div>
              <div className="count-text">Villas Served</div>
            </div>
            <div style={{ marginBottom: 20 }}>
              <div className="count-num">400+</div>
              <div className="count-text">Air conditioning Installed</div>
            </div>
            <div style={{ marginBottom: 20 }}>
              <div className="count-num">300+</div>
              <div className="count-text">Commercial Building Served</div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="whatsapp-container"
        onClick={handleLinkClick}
        style={{
          height: 67,
          position: 'fixed',
          bottom: 50,
          right: 60,
          borderRadius: 65,
          cursor: 'pointer',
          zIndex: 1,
        }}
      >
        <img
          // className="whatsapp-container"
          src="/green-whatsapp.png"
          alt=""
          width={100}
          style={{
            marginLeft: 11.5,
          }}
        />
      </div>
    </>
  )
}

export default Banner
