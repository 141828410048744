import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import './Clients.css'

import CarouselItem from './CarouselItem'

const Clients = () => {
  return (
    <>
      <div
        style={{
          margin: '100px auto 80px auto',
        }}
        className="booster-container"
      >
        <div
          style={{
            textAlign: 'center',
            color: '#252521',
            fontWeight: 700,
            fontSize: 40,
            marginBottom: 35,
          }}
        >
          Our Clients
        </div>
        <div
          className="client-container"
          style={{
            justifyContent: 'center',
            display: 'flex',
            flexWrap: 'wrap',
            // border: '1px solid black',
          }}
        >
          <div
            className="client-background"
            style={{
              width: '200px',
              background: '#ECECEC',
              borderRadius: 10,
              marginBottom: 30,
              marginRight: 20,
              textAlign: 'center',
              height: 120,
              padding: '8px 0px',
            }}
          >
            <img
              className="client-img"
              src="/golden-enclave.png"
              alt=""
              width="127px"
              height={120}
            />
          </div>

          <div
            className="client-background"
            style={{
              width: '200px',
              background: '#ECECEC',
              borderRadius: 10,
              marginBottom: 30,
              marginRight: 20,
              textAlign: 'center',
              height: 120,
              padding: '8px 0px',
            }}
          >
            <img
              className="client-img"
              src="/medical.png"
              alt=""
              width="127px"
              height="120px"
              // className="img-4"
            />
          </div>

          <div
            className="client-background"
            style={{
              width: '200px',
              background: '#ECECEC',
              borderRadius: 10,
              marginBottom: 30,
              textAlign: 'center',
              height: 120,
              marginRight: 20,
              padding: '8px 0px',
            }}
          >
            <img
              className="client-img"
              src="/snis.png"
              alt=""
              width="127px"
              height="120px"
              // className="img-4"
            />
          </div>

          <div
            className="client-background"
            style={{
              width: '200px',
              background: '#ECECEC',
              borderRadius: 10,
              marginBottom: 30,
              textAlign: 'center',
              height: 120,
              marginRight: 20,
              padding: '8px 0px',
            }}
          >
            <img
              className="client-img"
              src="/amc.png"
              alt=""
              width="127px"
              height="120px"
              // className="img-4"
            />
          </div>

          <div
            className="client-background"
            style={{
              width: '200px',
              background: '#ECECEC',
              borderRadius: 10,
              marginBottom: 30,
              textAlign: 'center',
              height: 120,
              marginRight: 20,
              padding: '8px 0px',
            }}
          >
            <img
              className="client-img"
              src="/nitte.png"
              alt=""
              width="127px"
              height="120"
              // className="img-4"
            />
          </div>

          <div
            className="client-background"
            style={{
              width: '200px',
              marginBottom: 30,
              background: '#ECECEC',
              borderRadius: 10,
              textAlign: 'center',
              height: 120,
              marginRight: 20,
              padding: '8px 0px',
            }}
          >
            <img
              className="client-img"
              src="/club.png"
              alt=""
              width="150px"
              height="120px"
            />
          </div>

          <div
            className="client-background"
            style={{
              width: '200px',
              marginBottom: 30,
              background: '#ECECEC',
              borderRadius: 10,
              textAlign: 'center',
              height: 120,
              marginRight: 20,
              padding: '8px 0px',
            }}
          >
            <img
              className="client-img"
              src="/spa.png"
              alt=""
              width="150px"
              height="120px"
            />
          </div>

          <div
            className="client-background"
            style={{
              width: '200px',
              background: '#ECECEC',
              borderRadius: 10,
              textAlign: 'center',
              // border: '1px solid red',
              marginBottom: 30,
              marginRight: 20,
              padding: '8px 0px',
            }}
          >
            <img
              className="client-imgVRR "
              src="/img4.png"
              alt=""
              width="150px"
              height="120px"
            />
          </div>

          <div
            className="client-background"
            style={{
              width: '200px',
              marginBottom: 30,
              marginRight: 20,
              background: '#ECECEC',
              borderRadius: 10,
              textAlign: 'center',
              height: 120,
              padding: '8px 0px',
            }}
          >
            <img className="client-img" src="/img3.png" alt="" width="150px" />
          </div>
        </div>
      </div>

      {/* -----------------Testimonials------------------ */}
      <div
        style={{
          textAlign: 'center',
          // maxWidth: 1270,
          margin: '150px auto  100px auto',
        }}
      >
        <div
          style={{
            color: '#656C78',
            fontSize: '14px',
            letterSpacing: 2,
            fontWeight: 700,
          }}
        >
          OUR TESTIMONIALS
        </div>

        <div
          style={{
            color: '#252521',
            fontWeight: 800,
            fontSize: '24px',
            marginTop: 15,
            marginBottom: 32,
          }}
        >
          Few words from our clients for us
        </div>
        <CarouselItem />
      </div>
    </>
  )
}

export default Clients
