import { useEffect, useRef, useState } from 'react'
import { Gallery } from 'react-grid-gallery'
import Lightbox from 'yet-another-react-lightbox'
import 'yet-another-react-lightbox/styles.css'
import { CustomImage, galleryImages } from './images'

const slides = galleryImages.map(({ src, width, height }) => ({
  src: src,
  width,
  height,
}))

const GalleryViewAll = ({ toggleFunction }) => {
  let menuRef = useRef()

  const [index, setIndex] = useState(-1)

  const handleClick = (index) => setIndex(index)

  useEffect(() => {
    let handler = (e) => {
      console.log('e.target contains', e.target)

      if (menuRef.current && !menuRef.current.contains(e.target)) {
        toggleFunction(false)
      }
    }

    document.addEventListener('mousedown', handler)
  })

  return (
    <div
      style={{
        maxWidth: '100vw',
        height: '100vh',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        position: 'fixed',
        background: 'rgba(49, 49, 49, 0.8)',
        zIndex: 1,
      }}
    >
      <div
        ref={menuRef}
        style={{
          position: 'absolute',
          background: 'white',
          borderRadius: 10,
          margin: 'auto',
          maxWidth: '95%',
          height: '95%',
          left: '0',
          right: '0',
          top: 50,
          bottom: 30,
          overflowY: 'scroll',
        }}
      >
        <Gallery
          margin={10}
          rowHeight={371}
          onClick={handleClick}
          enableImageSelection={false}
          images={galleryImages}
        />

        <Lightbox
          render={{
            buttonNext: () => null,
            buttonPrev: () => null,
          }}
          slides={slides}
          open={index >= 0}
          index={index}
          // close={() => setIndex(-1)}
        />
      </div>
    </div>
  )
}
export default GalleryViewAll
