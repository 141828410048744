import './App.css'
import Navbar from './Navbar'
import AboutUs from './AboutUs'
import OurProducts from './OurProducts'
import DealWith from './DealWith'
import Clients from './Clients'
import Gallery from './Gallery'
import Footer from './Footer'
// import FormTesting from './FormTesting'

function App() {
  return (
    <div>
      <Navbar />
      <div
        style={{
          width: '80%',
          margin: 'auto',
          alignItems: 'center',
          maxWidth: 1430,
          // border: '1px solid orange',
        }}
      >
        <AboutUs />
        <OurProducts />
        <DealWith />
        <Clients />
        <Gallery />
      </div>
      <Footer />
      {/* <FormTesting /> */}
    </div>
  )
}

export default App
